import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ContactList from "./ContactList";
import PublicContactList from "./PublicContactList";

export default function Contact(props) {
    
    return (

        <Container maxWidth="lg">
           <ContactList></ContactList>
           <PublicContactList></PublicContactList>
        </Container>

    )
}