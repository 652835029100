import axios from "axios";
import {authHeader, authHeaderForPhotos} from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getUser = (id) => {
    return axios.get(API_URL + `user/${id}`, {headers: authHeader()});
}

const addPhoto = (data, call) => {
    return axios.post(API_URL + "funeral/photo", data, {
            headers: authHeaderForPhotos(data),
            onUploadProgress: data => {
                call(data);
            },
        },
    )
};

const signDocument = (data) => {
    return axios.post(API_URL + "signatures", data, {
            headers: authHeaderForPhotos(data)
        },
    )
}

const unSignDocument = (values) => {
    return axios.put(API_URL + "signatures", values, {headers: authHeader()});
};

const createFuneral = (values) => {
    const {
        _id,
        funeral_type,
        contracting_authority,
        ca_address,
        ca_zip,
        ca_city,
        ca_state,
        ca_nationality,
        ca_email,
        ca_phone,
        ca_id,
        ca_birth,
        ca_relationship,
        deceased,
        d_address,
        d_zip,
        d_city,
        d_state,
        d_place_birth,
        d_gender,
        d_marital_status,
        d_birth,
        d_death,
        d_place_death,
        d_id,
        d_nationality,
        d_education,
        d_last_job,
        d_wife_husband,
        d_wife_husband_id,
        planned_pickup_place,
        planned_pickup,
        town_funeral,
        place_funeral,
        date_funeral,
        coffin_arrival,
        coffin_arrival_place,
        picked_items,
        notes,
    } = values;

    return axios.post(API_URL + "funeral", {
        _id,
        contracting_authority,
        funeral_type,
        ca_address,
        ca_zip,
        ca_city,
        ca_state,
        ca_nationality,
        ca_email,
        ca_phone,
        ca_id,
        ca_birth,
        ca_relationship,
        deceased,
        d_address,
        d_zip,
        d_city,
        d_state,
        d_place_birth,
        d_gender,
        d_marital_status,
        d_birth,
        d_death,
        d_place_death,
        d_id,
        d_nationality,
        d_education,
        d_last_job,
        d_wife_husband_id,
        d_wife_husband,
        planned_pickup_place,
        planned_pickup,
        town_funeral,
        place_funeral,
        date_funeral,
        coffin_arrival,
        coffin_arrival_place,
        picked_items,
        notes
    }, {headers: authHeader()});
};

const updateFuneral = (values) => {
    const {
        _id,
        funeral_type,
        contracting_authority,
        funeral_paid,
        ca_address,
        ca_zip,
        ca_city,
        ca_state,
        ca_nationality,
        ca_email,
        ca_phone,
        ca_id,
        ca_birth,
        ca_relationship,
        deceased,
        d_address,
        d_zip,
        d_city,
        d_state,
        d_place_birth,
        d_gender,
        d_marital_status,
        d_birth,
        d_death,
        d_place_death,
        d_id,
        d_nationality,
        d_education,
        d_last_job,
        d_wife_husband,
        d_wife_husband_id,
        planned_pickup_place,
        planned_pickup,
        town_funeral,
        place_funeral,
        date_funeral,
        coffin_arrival,
        coffin_arrival_place,
        picked_items,
        notes,
        funerio_id,
        flowii,
        other_party_person,
        other_party_comp,
    } = values;

    return axios.put(API_URL + "funeral", {
        _id,
        contracting_authority,
        funeral_type,
        funeral_paid,
        ca_address,
        ca_zip,
        ca_city,
        ca_state,
        ca_nationality,
        ca_email,
        ca_phone,
        ca_id,
        ca_birth,
        ca_relationship,
        deceased,
        d_address,
        d_zip,
        d_city,
        d_state,
        d_place_birth,
        d_gender,
        d_marital_status,
        d_birth,
        d_death,
        d_place_death,
        d_id,
        d_nationality,
        d_education,
        d_last_job,
        d_wife_husband_id,
        d_wife_husband,
        planned_pickup_place,
        planned_pickup,
        town_funeral,
        place_funeral,
        date_funeral,
        coffin_arrival,
        coffin_arrival_place,
        picked_items,
        notes,
        funerio_id,
        flowii,
        other_party_person,
        other_party_comp,
    }, {headers: authHeader()});
};

const createPlace = (values) => {
    const {
        _id,
        cremation,
        new_monument,
        depth_hole,
        cemetery,
        place_number,
        grave,
        uncover_grave,
        who_is_buried,
        period_buried,
        d_id,
        funeral_id,
        covering_cement,
        exhumation,
        owner
    } = values;

    return axios.post(API_URL + "place", {
        _id,
        cremation,
        new_monument,
        depth_hole,
        grave,
        cemetery,
        place_number,
        uncover_grave,
        who_is_buried,
        period_buried,
        d_id,
        covering_cement,
        exhumation,
        funeral_id,
        owner
    }, {headers: authHeader()});
};

const createDefinedTexts = (values) => {
    return axios.post(API_URL + "defined_texts", values, {headers: authHeader()});
};

const createEvidence = (values) => {
    const {
        _id,
        items,
        placement_fridge,
        car_id,
        temperature,
        disinfection,
        disinfection_date,
        funeral_id,
        autopsy,
        d_dangerous_disease,
        employee,
        employee_money,
        placement_of_decedent
    } = values;

    return axios.post(API_URL + "evidence", {
        _id,
        items,
        placement_fridge,
        car_id,
        temperature,
        disinfection,
        disinfection_date,
        autopsy,
        d_dangerous_disease,
        employee,
        employee_money,
        placement_of_decedent,
        funeral_id
    }, {headers: authHeader()});
};

const createServiceTemplate = (values) => {
    return axios.post(API_URL + "service_template", values, {headers: authHeader()});
};

const updateServiceTemplate = (values) => {
    return axios.put(API_URL + "service_template", values, {headers: authHeader()});
};

const updateServiceEntities= (values) => {
    return axios.put(API_URL + "service_entities", values, {headers: authHeader()});
};

const updatePassword = (values) => {
    return axios.put(API_URL + "user/password", values, {headers: authHeader()});
};

const updateStamp = (values) => {
    return axios.put(API_URL + "user/stamp", values, {headers: authHeader()});
};

const updateFlowii = (values) => {
    return axios.put(API_URL + "user/flowii", values, {headers: authHeader()});
};

const resetPassword = (values) => {
    return axios.put(API_URL + "admin/password", values, {headers: authHeader()});
}
const setFunerio = (values) => {
    return axios.put(API_URL + "admin/funerio", values, {headers: authHeader()});
}

const blockUnblockUser = (values) => {
    return axios.put(API_URL + "admin/moderate", values, {headers: authHeader()});
}

const setPremium = (values) => {
    return axios.post(API_URL + "admin/premium", values, {headers: authHeader()});
}

const deleteServiceTemplate = (id) => {
    return axios.delete(API_URL + `service_template/${id}`,  {headers: authHeader()});
};

const createProductTemplate = (values) => {
    return axios.post(API_URL + "product_template", values, {headers: authHeader()});
};

const editNotes = (values) => {
    return axios.post(API_URL + "funeral_notes", values, {headers: authHeader()});
};

const updateProductTemplate = (values) => {
    return axios.put(API_URL + "product_template", values, {headers: authHeader()});
};

const updateProductEntities= (values) => {
    return axios.put(API_URL + "product_entities", values, {headers: authHeader()});
};

const deleteProductTemplate = (id) => {
    return axios.delete(API_URL + `product_template/${id}`,  {headers: authHeader()});
};

const getListFunerals = (limit) => {
    return axios.get(API_URL + `list/${limit}`, {headers: authHeader()});
};

const getListFuneralsDownload = (year,month) => {
    return axios.get(API_URL + `download/${year}/${month}`, {headers: authHeader()});
};

const getFuneral = (id) => {
    return axios.get(API_URL + `funeral/${id}`, {headers: authHeader()});
};

const getSignatures = (id) => {
    return axios.get(API_URL + `signatures/${id}`, {headers: authHeader()});
};

const getPlace = (id) => {
    return axios.get(API_URL + `place/${id}`, {headers: authHeader()});
};

const getEvidence = (id) => {
    return axios.get(API_URL + `evidence/${id}`, {headers: authHeader()});
};

const deleteFuneral = (id) => {
    return axios.delete(API_URL + `funeral/${id}`, {headers: authHeader()});
}

const getUpcomingFunerals = () => {
    return axios.get(API_URL + 'funerals/upcoming_funeral', {headers: authHeader()});
};

const getUpcomingPickUp = () => {
    return axios.get(API_URL + 'funerals/upcoming_pickup', {headers: authHeader()});
};

const getUpcomingCoffin = () => {
    return axios.get(API_URL + 'funerals/upcoming_coffin', {headers: authHeader()});
};

const getGeneratedEvidence = (year) => {
    return axios.get(API_URL + `generated_evidence/${year}`, {headers: authHeader()});
}

const getAdminLogs = () => {
    return axios.get(API_URL + 'admin/logs', {headers: authHeader()});
}

const updateUser = (data) => {
    let at = authHeader();

    return axios.put(API_URL + "user/update", data , {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                'x-access-token': at["x-access-token"]
            }
        }
    );
}

const getTasks = (limit) => {
    return axios.get(API_URL + `user/tasks/${limit}`, {headers: authHeader()});
};

const getFuneralTasks = (limit, funeral_id) => {
    return axios.get(API_URL + `user/tasks/${funeral_id}/${limit}`, {headers: authHeader()});
};

const addTask = (values) => {
    return axios.post(API_URL + "user/task", values, {headers: authHeader()});
};

const updateTask = (values) => {
    return axios.put(API_URL + "user/task", values, {headers: authHeader()});
};

const deleteTask = (id) => {
    return axios.delete(API_URL + `user/task/${id}`, {headers: authHeader()});
};

const getTaskTemplates = () => {
    return axios.get(API_URL + 'task_templates/all', {headers: authHeader()});
};

const getGeneralTaskTemplates = () => {
    return axios.get(API_URL + `task_templates/1`, {headers: authHeader()});
};

const getFuneralTaskTemplates = () => {
    return axios.get(API_URL + `task_templates/2`, {headers: authHeader()});
};

const addTaskTemplate = (values) => {
    return axios.post(API_URL + "task_template", values, {headers: authHeader()});
};

const updateTaskTemplate = (values) => {
    return axios.put(API_URL + "task_template", values, {headers: authHeader()});
};

const deleteTaskTemplate = (id) => {
    return axios.delete(API_URL + `task_template/${id}`, {headers: authHeader()});
};

const getContacts = (limit) => {
    return axios.get(API_URL + `user/contacts/${limit}`, {headers: authHeader()});
};

const getPublicContacts = (limit) => {
    return axios.get(API_URL + `user/public_contacts/${limit}`, {headers: authHeader()});
};

const addContact = (values) => {
    return axios.post(API_URL + "user/contact", values, {headers: authHeader()});
};

const updateContact = (values) => {
    return axios.put(API_URL + "user/contact", values, {headers: authHeader()});
};

const deleteContact = (id) => {
    return axios.delete(API_URL + `user/contact/${id}`, {headers: authHeader()});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    setFunerio,
    setPremium,
    editNotes,
    updateFuneral,
    getGeneratedEvidence,
    getUser,
    getUpcomingCoffin,
    getUpcomingPickUp,
    getUpcomingFunerals,
    getPlace,
    getEvidence,
    deleteFuneral,
    createEvidence,
    createPlace,
    getFuneral,
    getSignatures,
    getListFunerals,
    getListFuneralsDownload,
    createFuneral,
    addPhoto,
    createServiceTemplate,
    updateUser,
    updateServiceTemplate,
    deleteServiceTemplate,
    updateServiceEntities,
    createProductTemplate,
    updateProductTemplate,
    updateProductEntities,
    deleteProductTemplate,
    updatePassword,
    updateStamp,
    updateFlowii,
    getAdminLogs,
    resetPassword,
    blockUnblockUser,
    signDocument,
    unSignDocument,
    createDefinedTexts,
    getTasks,
    addTask,
    updateTask,
    deleteTask,
    getFuneralTasks,
    getTaskTemplates,
    getGeneralTaskTemplates,
    getFuneralTaskTemplates,
    addTaskTemplate,
    updateTaskTemplate,
    deleteTaskTemplate,
    getContacts,
    getPublicContacts,
    addContact,
    updateContact,
    deleteContact
};