import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import UserService from "../../services/user.service";
import { logOut} from "../../helpers/logout";
import Paper from "@material-ui/core/Paper";
import useTable from "./useTable";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%'
        }
    },
    cardHeight: {
        height: '30vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    seeMore: {
        marginTop: theme.spacing(8),
    },
    marginTop: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    border: {
        borderBottom: '1 px'
    },
    box: {
        overflow: 'auto',
        height: '12vh',
    },
    marginBox: {
        margin: theme.spacing(1)
    }
}));

export default function PublicContactList(props) {
    const classes = useStyles();

    const [contacts, setContacts] = useState([]);
    const [filterFn, setFilterFn] = useState({
        fn: items => {
            return items;
        }
    });
    const [limit, setLimit] = useState(100);
    const fixedHeightPaper = clsx(classes.paper, classes.root);
    const seeMoreContainer = clsx(classes.seeMore, classes.root);

    const headCells = [
        {id: 'name', label: 'Názov'},
        {id: 'phone', label: 'Telefón'},
        {id: 'email', label: 'Email'},
    ]

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(contacts, headCells, filterFn);

    useEffect(() => {
        UserService.getPublicContacts(limit).then(
            (response) => {
                setContacts(response.data);
            },
            (e) => {
                if (e.response.status === 401) {
                    logOut(props);
                }
            }
        );
    }, [limit, props]);

    const handleNoLimit = () => {
        setLimit(0);
    }

    return (

        <Container maxWidth="lg">
            <div className={seeMoreContainer}>
                <div>
                    <div>
                        <Typography className={classes.instructions} component={'span'}>
                            <Paper className={fixedHeightPaper}>
                                <Title>Zoznam verejných kontaktov</Title>
                            <TblContainer>
                            <TblHead/>
                            <TableBody>
                                {
                                    recordsAfterPagingAndSorting().map((item, index) =>
                                        (<TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phone}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                        </TableRow>)
                                    )
                                }
                            </TableBody>
                        </TblContainer>
                                <TblPagination/>
                                <div>
                                    <Button size="small" color="primary"
                                            onClick={handleNoLimit}>Načítať viac ako max. 100 záznamov</Button>
                                </div>
                            </Paper>
                        </Typography>
                    </div>

                </div>
            </div>
        </Container>

        

    )
}