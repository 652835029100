import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import ContactsIcon from '@material-ui/icons/Contacts';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {Link} from "react-router-dom";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import StorageIcon from "@material-ui/icons/Storage";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import GavelIcon from '@material-ui/icons/Gavel';
import PersonPinIcon from '@material-ui/icons/PersonPin';

export const mainListItems = (
    <div>
        <ListSubheader inset>Hlavné menu</ListSubheader>
        <Link to="/domov" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Domov" />
            </ListItem>
        </Link>
        <Link to="/novy-pohreb" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Vytvoriť pohreb" />
            </ListItem>
        </Link>
        <Link to="/zoznam" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Zoznam pohrebov" />
            </ListItem>
        </Link>
        <Link to="/ulohy" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Úlohy" />
            </ListItem>
        </Link>
        <Link to="/kontakty" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <ContactsIcon/>
                </ListItemIcon>
                <ListItemText primary="Kontakty" />
            </ListItem>
        </Link>
    </div>
);

export const helperListItems = (
    <div>
        <ListSubheader inset>Pomôcky/nástroje</ListSubheader>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Nastavenia</ListSubheader>
        <Link to="/profil" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Profil" />
            </ListItem>
        </Link>
        <Link to="/definovane_texty" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <TextFieldsIcon />
                </ListItemIcon>
                <ListItemText primary="Preddefinované texty" />
            </ListItem>
        </Link>
        <Link to="/sluzby" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <SettingsApplicationsIcon/>
                </ListItemIcon>
                <ListItemText primary="Tvorba služieb" />
            </ListItem>
        </Link>
        <Link to="/produkty" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <AllInboxIcon/>
                </ListItemIcon>
                <ListItemText primary="Tvorba produktov" />
            </ListItem>
        </Link>
        <Link to="/definovane_ulohy" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Tvorba úloh" />
            </ListItem>
        </Link>
        <Link to="/generovanie_evidencie" style={{ textDecoration: 'none', color: '#565555' }} >
            <ListItem button>
                <ListItemIcon>
                    <StorageIcon/>
                </ListItemIcon>
                <ListItemText primary="Export evidencie" />
            </ListItem>
        </Link>
    </div>
);

export const docListItems = (
    <div>
        <ListSubheader inset>Právne dokumenty</ListSubheader>
        <Link to="/podmienky" style={{ textDecoration: 'none', color: '#565555' }}>
            <ListItem button>
                <ListItemIcon>
                    <GavelIcon/>
                </ListItemIcon>
                <ListItemText primary="Obchodné podmienky" />
            </ListItem>
        </Link>
        <Link to="/gdpr" style={{ textDecoration: 'none', color: '#565555' }}>
            <ListItem button>
                <ListItemIcon>
                    <PersonPinIcon/>
                </ListItemIcon>
                <ListItemText primary="GDPR" />
            </ListItem>
        </Link>
    </div>
);